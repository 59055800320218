.loader-background {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(66, 66, 66, 0.6);
    z-index: 9999;
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    color: #fafafa;
    box-sizing: border-box;
    animation: loaderAnimation 1s linear infinite;
}

@keyframes loaderAnimation {
    0% {
        box-shadow: -38px 0, -14px 0, 14px 0, 38px 0;
    }

    20% {
        box-shadow: -38px -12px, -14px 0, 14px 0, 38px 0;
    }

    40% {
        box-shadow: -38px 0, -14px -12px, 14px 0, 38px 0;
    }

    60% {
        box-shadow: -38px 0, -14px 0, 14px -12px, 38px 0;
    }

    80% {
        box-shadow: -38px 0, -14px 0, 14px 0, 38px -12px;
    }

    100% {
        box-shadow: -38px 0, -14px 0, 14px 0, 38px 0;
    }
}