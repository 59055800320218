:root {
  --primaryColor: #b71c1c;
  --accentAndTextColor: #FAFAFA;
  --backgroundColor: #424242;
  --errorColor: #B71C1C;
}

@font-face {
  font-family: 'RobotoMono-Regular';
  src: local('RobotoMono-Regular'), url(../fonts/RobotoMono-Regular.ttf) format('truetype');
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  font-family: "RobotoMono-Regular";
  line-height: 1.3 !important;
}

header {
  background-color: var(--primaryColor);
  width: 100%;
  height: 85px;
  position: absolute;
  z-index: 2;
  top: 0px;
  width: 100%;
}

nav {
  margin-right: 50px;
}

nav ul {
  list-style-type: none;
}

nav ul li {
  float: right;
  height: 35px;
  color: white;
  margin: 10px 20px 0 20px;
  font-size: 20px;
  text-decoration: none;
}

.title {
  margin: 10px 65px 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  display: block;
  position: absolute;
  cursor: default;
}

.title h1 {
  float: right;
  margin: 5px 5px 0px 30px;
}

.title img {
  margin-top: 5px;
}

.error_label {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 25px !important;
}

.error_label,
.error_message {
  width: 100%;
  text-align: center;
}

.error_message {
  color: var(--errorColor) !important;
  font-size: 20px !important;
}

footer {
  height: 80px;
  margin-top: 30px;
  width: 100%;
  font-size: 1em;
  color: var(--accentAndTextColor);
}

.footer_text {
  font-size: 1em;
  margin-left: 75px;
  margin-top: 12px;
  float: left
}

.footer_links img {
  width: 25px;
  height: 25px;
  margin: 10px;
}

.footer_links {
  float: right;
  margin-right: 75px;
}

.footer_bottom {
  position: relative;
}

.footer_fixed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

footer a,
footer a:link,
footer a:visited,
footer a:hover {
  color: var(--accentAndTextColor);
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1em;
}

.grid {
  margin: 75px auto 0;
  width: 100%;
  padding-top: 10px;
  padding: 50px;
  background-color: var(--backgroundColor);
  color: var(--accentAndTextColor);
}

.grid_container {
  max-width: 1000px;
}

.radio_checked,
.radio_unchecked {
  position: absolute;
  height: 20px;
  width: 20px;
}

.radio_unchecked {
  background-size: 20px 20px;
  background-color: transparent;
  background-image: url('../icons/radiobox_blank.svg');
}

.radio_checked {
  background-size: 20px 20px;
  background-image: url('../icons/radiobox_marked.svg');
  background-color: transparent;
}

.label_radio {
  margin-left: 30px;
  padding: 0 !important;
}

.button {
  display: inline-block;
  width: 60%;
  height: 30px;
  margin-left: 20%;
  margin-right: 20%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 0px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--primaryColor) !important;
}

.big_textbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid var(--accentAndTextColor);
  margin: 10px 0 20px;
  width: 100%;
  height: 400px;
  padding: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 16px;
  color: var(--accentAndTextColor);
  resize: none;
  background: var(--backgroundColor);
}

.div_radio {
  margin-top: 10px;
}

.table_buttons {
  width: 100%;
}

.table_radio {
  width: 100%;
}

.table_radio th {
  color: var(--accentAndTextColor) !important;
  font-size: 20px !important;
  padding: 5px;
}

.small_textbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: inline-block;
  border: 1px solid var(--accentAndTextColor);
  margin: 10px 0 20px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 16px;
  color: var(--accentAndTextColor);
  background: var(--backgroundColor);
}

@media only screen and (max-width: 768px) {
  .title h1 {
    margin: 0 0 0 0;
    font: normal 0px/1 Arial, Helvetica, sans-serif;
    width: 0px;
    visibility: collapse;
  }

  .label {
    width: 100%;
    text-align: center !important;
  }
}

.row_item_title {
  font-size: 20px;
  font-style: normal;
  width: 100%;
  color: var(--accentAndTextColor);
  margin-bottom: 0;
  text-decoration: none;
  cursor: pointer;
}

.row_item_div {
  width: 100%;
  padding: 20px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-decoration: none;
  border-radius: 10px;
  border: 1px solid #BDBDBD;
  cursor: pointer;
  margin-top: 15px;
  color: var(--accentAndTextColor);
  text-decoration: none;
  cursor: pointer;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 15px;
  /* gutter size */
  background-clip: padding-box;
}

.tool-section-title {
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
}

/*Text converter*/

.text_converter_table_select {
  width: 100%
}

.text_converter_table_th_select {
  width: 45%;
}

.text_converter_table_th_equal {
  width: 10%;
}

.text_converter_select_option {
  font-size: 20px;
  border: 1px solid var(--accentAndTextColor);
  background-color: var(--backgroundColor);
  display: block;
  padding: 20px;
}

.text_converter_select_from,
.text_converter_select_to {
  display: block;
  background-color: var(--backgroundColor);
  color: var(--accentAndTextColor);
  padding: 20px;
  border: 1px solid var(--accentAndTextColor);
  border-radius: 10px;
  font-size: 20px;
}

.text_converter_button {
  display: inline-block;
  width: 60%;
  height: 30px;
  margin-left: 20%;
  margin-right: 20%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 0px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--primaryColor);
}

.case_converter_button {
  display: inline-block;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 0px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--primaryColor);
}

.text_converter_unit_menu_property,
.text_converter_select_from,
.text_converter_select_to {
  width: 60%;
  margin-right: 20%;
  margin-left: 20%;
  text-align: center;
}

.text_converter_converter-equals {
  width: 100%;
  color: var(--accentAndTextColor);
  font-size: 30px;
  text-align: center;
}

.text_converter_textbox {
  display: inline-block;
  padding: 10px;
  border: 1px solid var(--accentAndTextColor);
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 220px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 16px;
  color: var(--accentAndTextColor);
  background: var(--backgroundColor);
  resize: none;
}

/* Unit converter */

.unit_converter_button {
  display: inline-block;
  width: 100%;
  height: 30px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 0px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--primaryColor);
}

.unit_converter_div_button {
  margin: 10px auto;
  max-width: 380px;
  width: 100%;
}

.unit_converter_div_equals {
  width: 100%;
  color: var(--accentAndTextColor);
  font-size: 2.4em;
  text-align: center;
}

.unit_converter_table {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.unit_converter_table_th_units {
  box-sizing: border-box;
  padding: 10px;
  width: 30%;
  border-top: 0px !important;
}

.unit_converter_table_th_textbox {
  box-sizing: border-box;
  padding: 10px;
  width: 70%;
}

.unit_converter_select {
  box-sizing: border-box;
  display: block;
  background-color: var(--backgroundColor);
  color: var(--accentAndTextColor);
  padding: 10px;
  border: 1px solid var(--accentAndTextColor);
  border-radius: 10px;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.unit_converter_select_units {
  box-sizing: border-box;
  display: block;
  background-color: var(--backgroundColor);
  color: var(--accentAndTextColor);
  padding: 10px;
  margin-bottom: 30px;
  border: 1px solid var(--accentAndTextColor);
  border-radius: 10px;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.unit_converter_textbox {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid var(--accentAndTextColor);
  width: 100%;
  text-align: center;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--backgroundColor);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Text encryptor */
.text_encryptor_error_label {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 25px !important;
}

.text_encryptor_error_label,
.text_encryptor_error_message {
  width: 100%;
  text-align: center;
}

.text_encryptor_error_message {
  color: var(--errorColor) !important;
  font-size: 20px !important;
}

.text_encryptor_radio_checked,
.text_encryptor_radio_unchecked {
  position: absolute;
  height: 20px;
  width: 20px;
}

.text_encryptor_radio_unchecked {
  background-size: 20px 20px;
  background-color: transparent;
  background-image: url('../icons/radiobox_blank.svg');
}

.text_encryptor_radio_checked {
  background-size: 20px 20px;
  background-image: url('../icons/radiobox_marked.svg');
  background-color: transparent;
}

.text_encryptor_label_radio {
  margin-left: 30px;
  padding: 0 !important;
}

.text_encryptor_button {
  display: inline-block;
  width: 60%;
  height: 30px;
  margin-left: 20%;
  margin-right: 20%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 0px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--primaryColor) !important;
}

.text_encryptor_big_textbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid var(--accentAndTextColor);
  margin: 10px 0 20px;
  width: 100%;
  height: 400px;
  padding: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 16px;
  color: var(--accentAndTextColor);
  resize: none;
  background: var(--backgroundColor);
}

.text_encryptor_div_radio {
  margin-top: 10px;
}

.text_encryptor_table_buttons {
  width: 100%;
}

.text_encryptor_table_buttons tbody tr th {
  width: 50%;
}

.text_encryptor_table_radio {
  width: 100%;
}

.text_encryptor_table_radio th {
  color: var(--accentAndTextColor) !important;
  font-size: 20px !important;
  padding: 5px;
}

.text_encryptor_small_textbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: inline-block;
  border: 1px solid var(--accentAndTextColor);
  margin: 10px 0 20px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 16px;
  color: var(--accentAndTextColor);
  background: var(--backgroundColor);
}

/* Barcode generator */

.barcode_generator_grid {
  margin: 80px auto 0px;
  width: 500px;
  padding-top: 10px;
  padding: 50px;
  background-color: var(--backgroundColor);
  color: var(--accentAndTextColor);
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.barcode_generator_radio_checked,
.barcode_generator_radio_unchecked {
  position: absolute;
  height: 20px;
  width: 20px;
}

.barcode_generator_radio_unchecked {
  background-size: 20px 20px;
  background-color: transparent;
  background-image: url('../icons/radiobox_blank.svg');
}

.barcode_generator_radio_checked {
  background-size: 20px 20px;
  background-image: url('../icons/radiobox_marked.svg');
  background-color: transparent;
}

.barcode_generator_label_radio {
  margin-left: 30px;
  padding: 0 !important;
}

.barcode_generator_barcode_content {
  display: inline-block;
  width: 100%;
  height: 150px;
  padding: 10px !important;
  font-size: 20px !important;
  color: var(--accentAndTextColor) !important;
  background: var(--backgroundColor) !important;
  border: 1px solid var(--accentAndTextColor);
  border-radius: 10px;
  outline: none;
  cursor: auto;
  box-sizing: border-box;
  resize: none;
}

.barcode_generator_barcode_img {
  background-color: white;
  padding: 10px;
  border: 1px solid var(--accentAndTextColor);
  border-radius: 10px;
  width: 100%;
}

.barcode_generator_label,
.barcode_generator_error_label {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: var(--accentAndTextColor) !important;
  margin-bottom: 10px;
}

.barcode_generator_label {
  font-size: 16px !important;
}

.barcode_generator_error_label {
  font-size: 20px !important;
}

.barcode_generator_column {
  float: left;
  width: 50% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.barcode_generator_table_radio {
  width: 100%;
}

.barcode_generator_table_radio th {
  color: var(--accentAndTextColor) !important;
  font-size: 20px !important;
  padding: 5px 0;
}

.barcode_generator_small_input {
  color: var(--accentAndTextColor) !important;
  font-size: 20px !important;
  width: 100%;
  background-color: var(--backgroundColor);
  border: 1px solid var(--accentAndTextColor);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}

.barcode_generator_button {
  width: 100%;
  cursor: pointer;
  padding: 10px 0;
  -webkit-border-radius: 10px;
  box-sizing: initial;
  border-radius: 10px;
  font-size: 20px;
  height: 30px;
  color: var(--accentAndTextColor);
  background-color: var(--primaryColor) !important;
}

.barcode_generator_div_offset {
  padding-bottom: 30px;
}

.barcode_generator_div_button {
  max-width: 300px;
  margin: 0 auto;
}

/* Password generator */

.password_generator_length {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  display: inline-block;
  padding: 10px 0;
  border: 1px solid var(--accentAndTextColor) !important;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  margin-top: 10px;
  width: 100%;
  color: var(--accentAndTextColor);
  cursor: auto;
  text-align: center;
  background: var(--backgroundColor);
}

.password_generator_button_generate {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-top: 30px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--primaryColor) !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.password_generator_password_label {
  margin: 10px 0 0;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
}

.password_generator_checkbox_checked,
.password_generator_checkbox_unchecked {
  position: absolute;
  height: 20px;
  width: 20px;
}

.password_generator_checkbox_unchecked {
  background-size: 20px 20px;
  background-color: transparent;
  background-image: url('../icons/checkbox-blank-outline.svg');
}

.password_generator_checkbox_checked {
  background-size: 20px 20px;
  background-image: url('../icons/checkbox-marked.svg');
  background-color: transparent;
}

.password_generator_label_checkbox {
  margin-left: 30px;
  padding: 0 !important;
}

.password_generator_div_button,
.password_generator_div_textbox {
  max-width: 300px;
}

.password_generator_div_checkboxes {
  max-width: 150px;
}

.password_generator_div_checkboxes,
.password_generator_div_button,
.password_generator_div_textbox {
  margin: 0 auto;
  width: 100%;
}

.password_generator_label {
  width: 100%;
  text-align: center;
  padding: 0 !important;
  font-weight: bold;
  margin-top: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Random number generator */

.random_number_generator_label {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.random_number_generator_input_box {
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--accentAndTextColor);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--accentAndTextColor);
  -o-text-overflow: clip;
  text-overflow: clip;
  text-align: center;
  background: var(--backgroundColor);
}

.random_number_generator_div_inputs {
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
}

.random_number_generator_div_button {
  max-width: 300px;
}

.random_number_generator_div_checkbox {
  max-width: 205px;
}

.random_number_generator_div_button,
.random_number_generator_div_checkbox {
  width: 100%;
  margin: 30px auto 0;
}

.random_number_generator_checkbox_checked,
.random_number_generator_checkbox_unchecked {
  position: absolute;
  height: 20px;
  width: 20px;
}

.random_number_generator_checkbox_unchecked {
  background-size: 20px 20px;
  background-color: transparent;
  background-image: url('../icons/checkbox-blank-outline.svg');
}

.random_number_generator_checkbox_checked {
  background-size: 20px 20px;
  background-image: url('../icons/checkbox-marked.svg');
  background-color: transparent;
}

.random_number_generator_button {
  width: 100%;
  height: 30px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: var(--accentAndTextColor);
  background: var(--primaryColor) !important;
}

.random_number_generator_label_checkbox {
  margin-left: 30px;
  margin-top: 2px;
  padding: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.random_number_generator_random_label {
  margin: 30px 0 0;
  width: 100%;
  scroll-behavior: unset;
  text-align: center;
  font-size: 20px;
}

/* Url parser */
.url_parser_property_title {
  font-weight: bold;
  width: 30%;
}

.url_parser_property_value {
  float: right;
  width: 70%;
}

.url_parser_property_title,
.url_parser_property_value {
  font-size: 20px;
  padding: 10px;
}

.url_parser_properties_table {
  margin: 50px auto 0;
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
  padding: 50px;
}

.url_parser_section_title {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin: 25px 0;
}

@media screen and (max-width: 900px) {
  .footer_text {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  .footer_links {
    float: none;
    margin: 10px auto 20px;
    width: 135px;
    padding-top: 50px;
  }
}